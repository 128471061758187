import React from "react"
import Layout from "../../components/layout/layout"
import SmallHero from "../../components/layout/smallHero"
import Illustration1 from "../../img/illustration/undraw_working_out.svg"
import { Helmet } from "react-helmet"

import "../mystyles.scss"

export default function Home() {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Présentation</title>
      </Helmet>
      <SmallHero title="Présentation" color="is-warning" />
      <section className="section">
        <div className="container has-text-centered">
          <h1 className="title">Team Kangourou</h1>
          <h2 className="subtitle">Progressons ensemble !</h2>
          <div className="columns">
            <div className="column is-two-fifths">
              <figure class="image">
                <img src={Illustration1} alt="placeholder illustration" />
              </figure>
            </div>
            <div className="colums">
              <div className="container">
                <p className="has-text-justified">
                  <br />
                  La Team Kangourou fut formée à l’origine par une bande de
                  potes qui voulait partager l’envie de courir. Ils se sont
                  focalisés à construire un environnement qui favorise la
                  progression et le dépassement de soi dans la course à pied.
                  Des entrainements réguliers ont été mis en place et différents
                  parcours sont organisés suivant les niveaux des participants.
                  <br />
                  Le club encourage aussi ses membres à participer à diverses
                  courses dans toute la Suisse. La Team Kangourou a construit un
                  esprit chaleureux et sportif qui favorise la dynamique de
                  groupe. C’est pourquoi le sport est parfois mis de côté pour
                  laisser place à des activités détentes.
                  <br />
                  C’est avec le slogan « progressons ensemble » que la Team
                  Kangourou continue à grandir et à avancer toujours plus vite !
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}
